<template>
  <div class="tab-pane active" id="products">
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <router-link
            :to="`/admin/customers/${$route.params.customerId}/products/create`"
            class="btn btn-success btn-floating float-right"
          >
            <i
              class="
                fas
                fa-plus-circle
                text-white
                font-size-22
                v-align-text-bottom
              "
            ></i>
          </router-link>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>{{ $t('adm.customer.associated-in') }}</th>
              <th>{{ $tc('generic-str.lbl-product', 1) }}</th>
              <th>
                {{ $tc('adm.customer.assign.credit', 2) }}
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr v-for="product in products" :key="product.id">
              <td>
                {{ product.created_at | date('DD/MM/YYYY HH:mm:ss') }}
              </td>
              <td>{{ product.name }}</td>
              <td>
                {{
                  product.plan.credits !== null
                    ? product.plan.credits
                    : $t('adm.customer.assign.unlimited')
                }}
              </td>
              <td class="action">
                <div class="btn-group">
                  <router-link
                    :to="`/admin/customers/${$route.params.customerId}/products/${product.id}`"
                    class="btn btn-primary"
                  >
                    <span class="icon dripicons-pencil"></span>
                  </router-link>
                  <button
                    @click="unsign(product.id)"
                    type="button"
                    class="btn btn-danger"
                  >
                    <span class="icon dripicons-trash"></span>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Swal from 'sweetalert2';
import Tab from '@/directives/Tab';
import { mask } from 'vue-the-mask';
import CustomerService from '@/services/customer.service';

export default {
  name: 'CustomerPlans',
  components: {},
  directives: {
    Tab,
    mask,
  },
  props: ['customer'],
  data() {
    return {
      loading: false,
      isSending: false,
      products: [],
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 4,
        masked: false,
      },
      pages: 1,
    };
  },
  created() {
    this.fetchProducts();
  },
  methods: {
    fetchProducts() {
      this.$store
        .dispatch('fetchAdminProducts', {
          account_id: this.$route.params.customerId,
          page: 1,
        })
        .then((response) => {
          this.products = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    unsign(productId) {
      Swal.fire({
        title: 'Remover Produto',
        text: 'O produto será removido permanentemente. Tem certeza que deseja fazer isso?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.value) {
          this.fetched = false;
          CustomerService.unsignProduct(
            this.$route.params.customerId,
            productId,
          ).then(
            () => {
              this.fetchProducts();
              this.$toast.show({
                title: 'Sucesso',
                content: 'Produto removido',
                type: 'success',
              });
            },
            (error) => {
              this.$toast.show({
                title: 'Erro',
                content: error.message,
                type: 'danger',
              });
              this.fetched = true;
            },
          );
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.loading-cep {
  right: 40px;
  top: 40px;
  position: absolute;
  padding: initial;
}
.select-container {
  display: block;
}
</style>
